import Vue from "vue";
import Vuetify from "vuetify/lib";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import i18n from "./i18n";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "md",
    theme: {
      primary: "#9652ff",
      success: "#3cd1c2",
      info: "#ffaa2c",
      error: "#f83e70"
    },
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  }
});
